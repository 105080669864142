import { Expose, Type } from 'class-transformer'
import { VerifyTransactionManualStatus } from '@interfaces/VerifyTransactionManualStatus'
import { PaymentMethodEnum } from '@interfaces/promotionCode/PaymentMethodEnum'
import { AdjustCoinStatusHistoriesDataType } from './AdjustCoinStatusDataType'

export class AdjustCoinStatusDataResponse {
  @Type(() => AdjustCoinStatusHistoriesDataType)
  data: AdjustCoinStatusHistoriesDataType[]

  total: number

  page: number
}

export class VerifyCoinStatusData {
  @Expose() status: VerifyTransactionManualStatus

  @Expose() paymentMethod: PaymentMethodEnum

  @Expose() amount: number

  @Expose() verifiedAt: string

  @Expose() errorMessage: string
}
