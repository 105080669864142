import { useState } from 'react'
import cn from 'classnames'

import { Button } from '@components/Button'
import { useDebounce } from '@hooks/useDebounce'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'
import { ConfirmModalProps } from './interface'

export function ConfirmModal({
  content,
  onConfirm,
  onClose,
  closeButtonClassName,
  confirmButtonClassName,
  isLoading = false,
  confirmButtonText = 'ยืนยัน',
  closeButtonText = 'ยกเลิก',
  hideConfirmButton = false,
  hideCloseButton = false,
}: ConfirmModalProps) {
  const [disabled, setDisabled] = useState(false)
  const { debounce } = useDebounce()

  function handleConfirm() {
    debounce(() => {
      setDisabled(true)
      onConfirm()
    }, 400)
  }

  return (
    <div className='p-[40px]'>
      {content}
      <div className='flex justify-center items-center text-[14px] font-medium'>
        {!hideCloseButton && (
          <Button
            type='button'
            className={closeButtonClassName}
            onClick={onClose}
          >
            {closeButtonText}
          </Button>
        )}
        {!hideConfirmButton && (
          <Button
            className={cn('text-white bg-primary', confirmButtonClassName)}
            type='button'
            disabled={isLoading || disabled}
            onClick={handleConfirm}
          >
            {confirmButtonText}
          </Button>
        )}
      </div>
    </div>
  )
}

ConfirmModal.Title = function Title({ children }: FunctionComponentType) {
  return (
    <div className='font-mitr text-[24px] text-secondary mb-[14px]'>
      {children}
    </div>
  )
}

ConfirmModal.Description = function Description({
  children,
}: FunctionComponentType) {
  return (
    <div className='font-mitr text-[16px] text-secondary mb-[14px]'>
      {children}
    </div>
  )
}
