import { useClient } from '@hooks/useClient'
import { addComma, formatDateDomini, getErrorMessage } from '@libs/utils'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { ConfirmModal } from '@components/modals/ConfirmModal'
import { useMutation } from 'react-query'
import { Loading } from '@components/Loading'
import { VerifyTransactionManualStatus } from '@interfaces/VerifyTransactionManualStatus'
import { AcceptActionCellProps, ActionCellProps } from './interface'

export function withActionCell(Component: React.FC<ActionCellProps>) {
  function WithActionCell({
    id,
    transactionId,
    refetchGetCoinToAdjustHistories,
    ...props
  }: AcceptActionCellProps) {
    const client = useClient()
    const alert = useAlert()
    const confirmAdjustStatusModal = useModal({ modal: 'confirm' })

    const { mutate: adjustStatus } = useMutation(
      (recordId: number) => client!.coinClient.adjustCoinStatus(recordId),
      {
        onSuccess: async () => {
          if (refetchGetCoinToAdjustHistories) {
            await refetchGetCoinToAdjustHistories()
            alert.success('ปรับสถานะสำเร็จ')
            confirmAdjustStatusModal.hide()
          }
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
          confirmAdjustStatusModal.hide()
        },
      }
    )
    async function handleAdjustStatus() {
      confirmAdjustStatusModal.show({
        isLoading: true,
        hideConfirmButton: true,
        hideCloseButton: true,
        content: (
          <>
            <ConfirmModal.Title>
              <div className='w-full text-center'>ข้อมูลการเติมเหรียญ</div>
            </ConfirmModal.Title>
            <div className='flex justify-center mb-4'>
              <Loading size={24} />
            </div>
          </>
        ),
      })

      try {
        const verifyTransactionManual =
          await client!.coinClient.getVerifyCoinStatus(id)

        confirmAdjustStatusModal.show({
          content: (
            <>
              <ConfirmModal.Title>
                <div className='w-full text-center'>ข้อมูลการเติมเหรียญ</div>
              </ConfirmModal.Title>
              <ConfirmModal.Description>
                <ul>
                  {[
                    {
                      label: 'transactionId:',
                      value: transactionId,
                    },
                    {
                      label: 'ช่องทาง:',
                      value: verifyTransactionManual.paymentMethod,
                    },
                    {
                      label: 'จำนวนเงินที่ชำระ:',
                      value: `${addComma(verifyTransactionManual.amount)} บาท`,
                    },
                    {
                      label: 'สถานะ:',
                      value:
                        verifyTransactionManual.status ===
                        VerifyTransactionManualStatus.SUCCESS ? (
                          <span className='text-green'>สำเร็จ</span>
                        ) : (
                          <span className='text-red'>ไม่สำเร็จ</span>
                        ),
                    },
                    {
                      label: 'เวลาที่ตรวจสอบ:',
                      value: formatDateDomini(
                        verifyTransactionManual.verifiedAt,
                        true,
                        true,
                        ' / '
                      ),
                    },
                  ].map(({ label, value }) => (
                    <li>
                      {label}{' '}
                      <span className='font-thin font-sarabun'>{value}</span>
                    </li>
                  ))}
                </ul>
                {verifyTransactionManual.errorMessage && (
                  <div className='text-red mt-2 break-words font-sarabun font-thin'>
                    เนื่องจาก {verifyTransactionManual.errorMessage}
                  </div>
                )}
              </ConfirmModal.Description>
            </>
          ),
          closeButtonClassName: !!verifyTransactionManual.errorMessage
            ? 'border border-black rounded-full'
            : '',
          closeButtonText: !!verifyTransactionManual.errorMessage
            ? 'ปิด'
            : 'ยกเลิก',
          confirmButtonText: 'ปรับสถานะ',
          hideConfirmButton: !!verifyTransactionManual.errorMessage,
          onConfirm: () => adjustStatus(id),
          onClose: () => confirmAdjustStatusModal.hide(),
        })
      } catch (error) {
        confirmAdjustStatusModal.show({
          closeButtonClassName: 'border border-black rounded-full',
          closeButtonText: 'ปิด',
          hideConfirmButton: true,
          content: (
            <>
              <ConfirmModal.Title>
                <div className='w-full text-center'>เกิดช้อผิดพลาด</div>
              </ConfirmModal.Title>
              <ConfirmModal.Description>
                <ul>
                  <li>
                    transactionId:{' '}
                    <span className='font-thin font-sarabun'>
                      {transactionId}
                    </span>
                  </li>
                </ul>
                <div className='text-red mt-2'>
                  ดึงข้อมูล Inquiry ไม่สำเร็จ
                  <div className='text-sm font-normal break-words'>
                    {getErrorMessage(error)}
                  </div>
                </div>
              </ConfirmModal.Description>
            </>
          ),
        })
      }
    }

    const componentProps = {
      ...props,
      id,
      handleAdjustStatus,
    }

    return <Component {...componentProps} />
  }

  return WithActionCell
}
