import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import cloneDeep from 'lodash.clonedeep'
import { DateTime } from 'luxon'

import { client as appClient } from '@client/init'
import { CoinResponse } from '@models/coin/CoinResponse'
import {
  AdjustCoinStatusDataResponse,
  VerifyCoinStatusData,
} from '@models/coin/AdjustCoinStatusDataResponse'
import { SortingEnum } from '@interfaces/SortingEnum'
import { CoinRequestResponse } from '@models/coin/CoinRequestResponse'
import { CoinOptionType } from '@models/coin/CoinOptionType'
import { CoinType } from '@models/coin/CoinType'
import { CoinUnitEnum } from '@interfaces/CoinUnitEnum'
import { CoinRequestStatusEnum } from '@interfaces/CoinRequestStatusEnum'
import { CoinStatusEnum } from '@interfaces/CoinStatusEnum'
import { CoinFormType } from '@models/coin/CoinFormType'
import { CoinHistoryResponse } from '@models/coin/CoinHistoryResponse'
import { CoinTypeEnum } from '@interfaces/coin/CoinTypeEnum'
import { CoinHistoryQueryParam } from '@features/coin/pages/CoinHistoryPage/interface'
import { CoinHistoryDataResponse } from '@models/coin/CoinHistoryDataResponse'
import { CoinHistoryTypeEnum } from '@interfaces/coin/CoinHistoryTypeEnum'
import { CoinDetailParam } from '@features/coin/pages/SummaryCoinPage/components/CoinDetailTable/interface'
import { SystemCoinType } from '@models/coin/SystemCoinType'
import { ActiveCoinType } from '@models/coin/ActiveCoinType'
import { UserRemainCoinResponse } from '@models/coin/UserRemainCoinResponse'
import { SortByType } from '@components/Table/interface'
import { TopSpenderType } from '@models/coin/TopSpenderType'
import { COINS } from './schemas/coins'
import { COIN_REQUESTS } from './schemas/coinRequests'
import { COIN_OPTIONS } from './schemas/coinOptions'
import { CREATE_COIN_REQUEST } from './schemas/createCoinRequest'
import { UPDATE_COIN_REQUEST_STATUS } from './schemas/updateCoinRequest'
import { CREATE_COIN } from './schemas/createCoin'
import { COIN_HISTORIES } from './schemas/getCoinHistories'
import { COIN_TO_ADJUST_HISTORIES } from './schemas/getCoinToAdjustHistories'
import {
  ADJUST_COIN_STATUS,
  VERIFY_COIN_STATUS,
} from './schemas/adjustCoinStatus'
import { SYSTEM_COINS } from './schemas/systemCoins'
import { USER_REMAIN_COINS } from './schemas/userRemainCoins'
import { GET_ACTIVE_COINS } from './schemas/getActiveCoins'
import { GET_TOP_COIN_SPENDER } from './schemas/getTopCoinSpender'
import { CREATE_APPLE_FINANCIAL_REPORT } from './schemas/createAppleFinancialReport'
import { LATEST_UPDATE_IN_APP_PURCHASE } from './schemas/latestUpdateInAppPurchasesAppleTransaction'
import { EXPORT_COIN_HISTORY } from './schemas/exportCoinHistory'
import { EXPORT_COIN_REMAIN } from './schemas/exportCoinRemain'
import { EXPORT_COIN_SUMMARY } from './schemas/exportCoinSummary'

export class CoinClient {
  constructor(private client: GraphQLClient) {}

  async getCoinOptions(): Promise<CoinOptionType[]> {
    const { coins } = await this.client.request(COIN_OPTIONS, {
      orderBy: {
        createdAt: SortingEnum.ASC,
      },
      where: {
        status: CoinStatusEnum.ACTIVE,
      },
    })
    return plainToInstance(CoinOptionType, coins.data as [])
  }

  async getPromotionChallengeCoinOptions(): Promise<CoinOptionType[]> {
    const { coins } = await this.client.request(COIN_OPTIONS, {
      orderBy: {
        createdAt: SortingEnum.ASC,
      },
      type: CoinTypeEnum.FREE,
      where: {
        status: CoinStatusEnum.ACTIVE,
      },
    })
    return plainToInstance(CoinOptionType, coins.data as [])
  }

  async getVisibleActiveCoinOptions(): Promise<CoinOptionType[]> {
    const { coins } = await this.client.request(COIN_OPTIONS, {
      orderBy: {
        createdAt: SortingEnum.ASC,
      },
      where: {
        status: CoinStatusEnum.ACTIVE,
        visible: true,
      },
    })
    return plainToInstance(CoinOptionType, coins.data as [])
  }

  async getCoins(): Promise<CoinResponse> {
    const { coins } = await this.client.request(COINS, {
      limitPerPage: 100,
      page: 1,
      orderBy: {
        createdAt: SortingEnum.ASC,
      },
    })
    return plainToInstance(CoinResponse, coins)
  }

  async getCoinRequests({
    limitPerPage,
    page,
  }: {
    limitPerPage: number
    page: number
  }): Promise<CoinRequestResponse> {
    const { coinRequests } = await this.client.request(COIN_REQUESTS, {
      limitPerPage,
      page,
      orderBy: {
        createdAt: SortingEnum.DESC,
      },
    })
    return plainToInstance(CoinRequestResponse, coinRequests)
  }

  async createCoinRequest(coins: CoinType[]): Promise<void> {
    const dataClone = cloneDeep(coins)
    for (let i = 0; i < coins.length; i += 1) {
      if (coins[i].imgPath.blob) {
        // eslint-disable-next-line no-await-in-loop
        dataClone[i].imgPath.url = await appClient.fileClient.uploadFile(
          coins[i].imgPath.blob!
        )
      }
    }

    await this.client.request(CREATE_COIN_REQUEST, {
      createCoinRequestInput: {
        coins: dataClone.map(item => ({
          id: item.id,
          name: item.name,
          imgPath: item.imgPath.url,
          amountCoin: item.amountCoin,
          amountCoinPerValue: item.amountCoinPerValue,
          valueUnitType: item.valueUnitCoinId
            ? CoinUnitEnum.COIN
            : CoinUnitEnum.THB,
          valueUnitCoinId: item.valueUnitCoinId || undefined,
          isExpireByActivity: item.isExpireByActivity,
          isExpireByPaid: item.isExpireByPaid,
          expireWithinMonthsByActivity: item.expireWithinMonthsByActivity,
          expireWithinMonthsByPaid: item.expireWithinMonthsByPaid,
          visible: item.visible,
        })),
      },
    })
  }

  async updateCoinRequestStatus({
    id,
    status,
  }: {
    id: number
    status: CoinRequestStatusEnum
  }): Promise<void> {
    await this.client.request(UPDATE_COIN_REQUEST_STATUS, {
      updateCoinRequestStatusInput: { id, status },
    })
  }

  async createCoin(form: CoinFormType): Promise<void> {
    let imgPath = ''
    if (form.upload.blob) {
      imgPath = await appClient.fileClient.uploadFile(form.upload.blob)
    }
    await this.client.request(CREATE_COIN, {
      createCoinInput: {
        name: form.name,
        imgPath,
      },
    })
  }

  async getCoinHistories({
    limitPerPage,
    page,
  }: {
    limitPerPage: number
    page: number
  }): Promise<CoinHistoryResponse> {
    const { coinRequests } = await this.client.request(COIN_REQUESTS, {
      limitPerPage,
      page,
      orderBy: {
        createdAt: SortingEnum.DESC,
      },
    })
    return plainToInstance(CoinHistoryResponse, coinRequests)
  }

  async getCoinToAdjustData({
    limitPerPage,
    page,
    searchParam,
  }: {
    limitPerPage: number
    page: number
    searchParam: CoinHistoryQueryParam
  }): Promise<AdjustCoinStatusDataResponse> {
    const { listTransactions } = await this.client.request(
      COIN_TO_ADJUST_HISTORIES,
      {
        limitPerPage,
        page,
        orderBy: {
          [searchParam.sortKey]: searchParam.sortOrder,
        },
        startDate: searchParam.startAt.toUTC(),
        endDate: searchParam.endAt.toUTC(),
        searchText: searchParam.searchText,
      }
    )
    return plainToInstance(AdjustCoinStatusDataResponse, listTransactions)
  }

  async getVerifyCoinStatus(id: number): Promise<VerifyCoinStatusData> {
    const { verifyTransactionManual } = await this.client.request(
      VERIFY_COIN_STATUS,
      {
        verifyTransactionInput: {
          id,
        },
      }
    )

    return plainToInstance(VerifyCoinStatusData, verifyTransactionManual)
  }

  async adjustCoinStatus(id: number): Promise<void> {
    await this.client.request(ADJUST_COIN_STATUS, {
      updateTransactionInput: {
        id,
      },
    })
  }

  async getCoinHistoriesData({
    limitPerPage,
    page,
    coinHistoryType,
    searchParam,
  }: {
    limitPerPage: number
    page: number
    coinHistoryType: CoinHistoryTypeEnum
    searchParam: CoinHistoryQueryParam
  }): Promise<CoinHistoryDataResponse> {
    const { coinHistories } = await this.client.request(COIN_HISTORIES, {
      coinHistoryType,
      limitPerPage,
      page,
      orderBy: {
        [searchParam.sortKey]: searchParam.sortOrder,
      },
      startDate: searchParam.startAt.toUTC(),
      endDate: searchParam.endAt.toUTC(),
      searchText: searchParam.searchText,
    })
    return plainToInstance(CoinHistoryDataResponse, coinHistories)
  }

  async getSystemCoins({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }): Promise<SystemCoinType[]> {
    const { systemCoins } = await this.client.request(SYSTEM_COINS, {
      getSystemCoinsInput: {
        startDate: startDate.toUTC(),
        endDate: endDate.toUTC(),
      },
    })

    return plainToInstance(SystemCoinType, systemCoins as [])
  }

  async userRemainCoins({
    perpage,
    page,
    sortValue,
    sortKey,
    paidCoinId,
    secondaryCoinId,
    activityCoinId,
    searchParam,
  }: CoinDetailParam): Promise<UserRemainCoinResponse> {
    const { userRemainCoins } = await this.client.request(USER_REMAIN_COINS, {
      searchText: searchParam,
      limitPerPage: perpage,
      page,
      orderBy: {
        [sortKey]: sortValue,
      },
      paidCoinId,
      secondaryCoinId,
      activityCoinId,
    })

    return plainToInstance(UserRemainCoinResponse, userRemainCoins)
  }

  async getActiveCoins(): Promise<ActiveCoinType[]> {
    const { coins } = await this.client.request(GET_ACTIVE_COINS)

    return plainToInstance(ActiveCoinType, coins.data as [])
  }

  async getTopCoinSpender({
    searchParam,
    sort,
  }: {
    searchParam: {
      startAt: DateTime
      endAt: DateTime
    }
    sort: SortByType
  }): Promise<TopSpenderType[]> {
    const { getTopCoinSpender } = await this.client.request(
      GET_TOP_COIN_SPENDER,
      {
        startDate: searchParam.startAt.toUTC(),
        endDate: searchParam.endAt.toUTC(),
        orderBy: sort.key
          ? {
              [sort.key]: sort.order,
            }
          : undefined,
      }
    )

    return plainToInstance(TopSpenderType, getTopCoinSpender as [])
  }

  async createAppleFinancialReport({
    appleReportFile,
    financialReportFile,
  }: {
    appleReportFile: File | Blob
    financialReportFile: File | Blob
  }): Promise<void> {
    await this.client.request(CREATE_APPLE_FINANCIAL_REPORT, {
      createAppleFinancialReportInput: {
        appleReportFile,
        financialReportFile,
      },
    })
  }

  async latestUpdateInAppPurchases(): Promise<string | undefined> {
    const { latestUpdateInAppPurchasesAppleTransaction } =
      await this.client.request(LATEST_UPDATE_IN_APP_PURCHASE)
    return latestUpdateInAppPurchasesAppleTransaction
      ? DateTime.fromISO(latestUpdateInAppPurchasesAppleTransaction)
          .setLocale('th')
          .toFormat('dd LLL yyyy HH:mm:ss')
      : undefined
  }

  async exportCoinHistory({
    searchParam,
  }: {
    searchParam: CoinHistoryQueryParam
  }) {
    await this.client.request(EXPORT_COIN_HISTORY, {
      startDate: searchParam.startAt.toUTC(),
      endDate: searchParam.endAt.toUTC(),
      searchText: searchParam.searchText,
      orderBy: {
        [searchParam.sortKey]: searchParam.sortOrder,
      },
    })
  }

  async exportCoinRemain({ sort }: { sort: SortByType }) {
    await this.client.request(EXPORT_COIN_REMAIN, {
      orderBy: {
        [sort.key]: sort.order,
      },
    })
  }

  async exportCoinSummary({
    startDate,
    endDate,
  }: {
    startDate: DateTime
    endDate: DateTime
  }) {
    await this.client.request(EXPORT_COIN_SUMMARY, {
      startDate: startDate.toUTC(),
      endDate: endDate.toUTC(),
    })
  }
}
