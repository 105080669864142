import { gql } from 'graphql-request'

export const ADJUST_COIN_STATUS = gql`
  mutation UpdateTransactionManual(
    $updateTransactionInput: UpdateTransactionInput!
  ) {
    updateTransactionManual(updateTransactionInput: $updateTransactionInput) {
      status
    }
  }
`

export const VERIFY_COIN_STATUS = gql`
  query VerifyTransactionManual(
    $verifyTransactionInput: VerifyTransactionInput!
  ) {
    verifyTransactionManual(verifyTransactionInput: $verifyTransactionInput) {
      status
      paymentMethod
      amount
      verifiedAt
      errorMessage
    }
  }
`
